import { MotifProgressLoader } from '@ey-xd/motif-react';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import DataCategoryExplorerPage from 'components/pageContents/pages/dataCategoryExplorerPage/dataCategoryExplorerPage.component';
import RegulatoryLineagePage from 'components/pageContents/pages/regulatoryLineagePage/regulatoryLineagePage.component';
import DataSourcingPage from 'components/pageContents/pages/sapDataSourcingPage/sapDataSourcingPage.component';
import SapLineagePage from 'components/pageContents/pages/sapLineagePage/sapLineagePage.component';
import ThematicLineagePage from 'components/pageContents/pages/thematicLineagePage/thematicLineagePage.component';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApiStatus } from 'shared/models/apiStatus.enum';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import { getFrontendPermissions } from 'shared/redux/slice/userProfileSlice';

import Custom404 from './components/pageContents/pages/custom404Page/custom404Page.component';
import HomePage from './components/pageContents/pages/homePage/homePage.component';

interface RoutingProps {
  isSideNavCollapsed: boolean;
}

const Routings = ({ isSideNavCollapsed }: RoutingProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { userPermissionsError, userPermissionsStatus } = useAppStore().userProfileSlice;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      dispatch(getFrontendPermissions());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userPermissionsStatus == ApiStatus.ERROR) {
      setError(userPermissionsError);
    }
  }, [userPermissionsError, userPermissionsStatus]);

  const handleErrorModalClose = () => {
    setError('');
  };

  return (
    <>
      {loading ? (
        <MotifProgressLoader show={loading} />
      ) : (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Custom404 />} />
          <Route path="/regulatoryLineage" element={<RegulatoryLineagePage />} />
          <Route
            path="/dataCategoryExplorer"
            element={<DataCategoryExplorerPage isSideNavCollapsed={isSideNavCollapsed} />}
          />
          <Route path="/thematicLineage" element={<ThematicLineagePage />} />
          <Route
            path="/dataSourcing"
            element={<DataSourcingPage isSideNavCollapsed={isSideNavCollapsed} />}
          />
          <Route path="/sapLineage" element={<SapLineagePage />} />
        </Routes>
      )}

      {error && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}
    </>
  );
};
export default Routings;
