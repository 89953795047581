import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseError } from 'shared/core/IError';
import { ApiStatus } from 'shared/models/apiStatus.enum';
import { UserState } from 'shared/models/userModel';
import { httpClient } from 'shared/utils/httpClient';
import { callMsGraphApis } from 'shared/utils/msalApiCalls';

import { RootState } from '../store';

export const getUserProfile = createAsyncThunk<any, void, { rejectValue: ResponseError }>(
  'userProfileSlice/getUserProfile',
  async (_, thunkApi) => {
    try {
      const userData = await callMsGraphApis('', false);
      return userData;
    } catch (error) {
      console.log('🚀 ~ error:', error);
      return thunkApi.rejectWithValue({ message: 'Failed to get user profile' });
    }
  }
);

export const getFrontendPermissions = createAsyncThunk<any, void, { rejectValue: ResponseError }>(
  'userProfileSlice/getFrontendPermissions',
  async (_, thunkApi) => {
    try {
      const response = await httpClient.get('/authentication/frontend-permissions');
      if (response.data) {
        return response.data;
      }
      return thunkApi.rejectWithValue({ message: 'Failed to get user permissions' });
    } catch (error) {
      return thunkApi.rejectWithValue({ message: 'Failed to get user permissions' });
    }
  }
);

export const getUserAvatar = createAsyncThunk<any, void, { rejectValue: ResponseError }>(
  'userProfileSlice/getUserAvatar',
  async (_, thunkApi) => {
    try {
      const photoSize = '64x64'; // 48x48, 64x64, 96x96, 120x120, 240x240, 360x360, 432x432, 504x504, and 648x648.

      const avatar: any = await callMsGraphApis(`/photos/${photoSize}/$value`, true);
      if (avatar) {
        const url = window.URL || window.webkitURL;
        const blobUrl = await url.createObjectURL(avatar);
        return blobUrl;
      }
      return thunkApi.rejectWithValue({ message: 'Failed to get user avatar' });
    } catch (error) {
      return thunkApi.rejectWithValue({ message: 'Failed to get user avatar' });
    }
  }
);

const initialState: UserState = {
  name: '',
  emailId: '',
  id: '',
  avatar: '',
  profileApiStatus: ApiStatus.NONE,
  profileApiError: '',
  userInfo: {},
  userPermissions: [],
  userPermissionsError: '',
  userPermissionsStatus: ApiStatus.NONE
};

const userProfileSlice = createSlice({
  name: 'userProfileSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserProfile.pending, (state) => {
      state.profileApiStatus = ApiStatus.LOADING;
    });
    builder.addCase(getUserProfile.rejected, (state, { payload }) => {
      state.profileApiError = payload?.message.toString() || '';
      state.profileApiStatus = ApiStatus.ERROR;
    });
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.profileApiStatus = ApiStatus.SUCCESS;
      state.name = payload?.displayName;
      state.emailId = payload?.mail;
      state.id = payload?.id;
      state.userInfo = {
        firstName: payload?.givenName,
        lastName: payload?.surname,
        emailAddress: payload?.mail,
        userId: payload?.id
      };
    });
    builder.addCase(getUserAvatar.pending, (state) => {
      state.profileApiStatus = ApiStatus.LOADING;
    });
    builder.addCase(getUserAvatar.rejected, (state, { payload }) => {
      state.profileApiError = payload?.message.toString() || '';
      state.profileApiStatus = ApiStatus.ERROR;
    });
    builder.addCase(getUserAvatar.fulfilled, (state, { payload }) => {
      state.profileApiStatus = ApiStatus.SUCCESS;
      state.avatar = payload;
    });
    builder.addCase(getFrontendPermissions.pending, (state) => {
      state.userPermissionsStatus = ApiStatus.LOADING;
    });
    builder.addCase(getFrontendPermissions.rejected, (state, { payload }) => {
      state.userPermissionsStatus = ApiStatus.ERROR;
      state.userPermissionsError = payload?.message.toString() || '';
    });
    builder.addCase(getFrontendPermissions.fulfilled, (state, { payload }) => {
      state.userPermissionsStatus = ApiStatus.SUCCESS;
      state.userPermissions = payload;
    });
  }
});

export const selectUserProfile = (state: RootState) => {
  return state.userProfileSlice;
};

export default userProfileSlice.reducer;
