import { SideMenuIcons } from 'images/sideMenuIcons';
import { MenuList } from 'shared/models/SideNav';

export const APP_NAME = 'ESG Data & Requirements Navigator';

export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL || '';
export const BACKEND_DOTNET_URL = process.env.REACT_APP_BACKEND_DOTNET_URL || '';
export const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || '';
export const SSO_TENANT_ID = process.env.REACT_APP_SSO_TENANT_ID || '';

export const REGULATIONS_COLOR_CODE = {
  CSRD: '#1D8583',
  TCFD: '#1275C1',
  SFDR: '#B26100',
  ISSB: '#8965CC',
  PCAF: '#B14891',
  USSEC: '#1F893F',
  B15: '#9897A6',
  ESRS2023: '#1D8583',
  USSEC3311275: '#1F893F'
};

export const SIDE_MENU_ITEMS: MenuList[] = [
  {
    menuItemName: 'Home',
    path: '/',
    iconName: SideMenuIcons.homeAltSvg
  },
  {
    menuItemName: 'Lineage',
    path: '',
    iconName: SideMenuIcons.dashboardDotsSvg,
    subMenu: [
      {
        menuItemName: 'Regulatory Lineage',
        path: '/regulatoryLineage',
        iconName: ''
      },
      {
        menuItemName: 'Thematic Lineage',
        path: '/thematicLineage',
        iconName: ''
      },
      {
        menuItemName: 'Data Sourcing Lineage',
        path: '/sapLineage',
        iconName: SideMenuIcons.dashboardDotsSvg
      }
    ]
  },
  {
    menuItemName: 'Data Sourcing',
    path: '/dataSourcing',
    iconName: SideMenuIcons.mediaVideoSvg
  },
  {
    menuItemName: 'Data Category Explorer',
    path: '/dataCategoryExplorer',
    iconName: SideMenuIcons.dbSvg
  }
];

export const SAP_SIDE_MENU_ITEMS: MenuList[] = [
  {
    menuItemName: 'Home',
    path: '/saphome',
    iconName: SideMenuIcons.homeAltSvg
  },
  {
    menuItemName: 'Data Sourcing Lineage',
    path: '/saphome/sapLineage',
    iconName: SideMenuIcons.dashboardDotsSvg
  },
  {
    menuItemName: 'Data Sourcing',
    path: '/saphome/dataSourcing',
    iconName: SideMenuIcons.mediaVideoSvg
  }
];

export const colors: Array<string> = [
  '#FF810A',
  '#34C768',
  '#B14891',
  '#C4C4CD',
  '#4EBEEB',
  '#B26100',
  '#229B9A',
  '#8965CC',
  '#DA5BB3',
  '#FF735E',
  '#5E36BC',
  '#1D8583',
  '#B094DC',
  '#55A6FC',
  '#ECBDDA',
  '#8BF9A2',
  '#8BF4F1',
  '#E79ECD',
  '#AFCDFB',
  '#B094DC'
];

// Redux action type values

export const GET_HOMEPAGE_DATA = 'data/homePage';
export const GET_SAPHOMEPAGE_DATA = 'data/saphomePage';
export const GET_REGULATORY_LINEAGE_DATA = 'data/regulatoryLineage';

// APIs
export const homePageDataUrl = `home/home-screen-data`;
export const overlapAnalysisApi = {
  analysisByRegulationUrl: (regulationName?: string) =>
    `home/overlap-analysis/search?regulationName=${regulationName}`
};
export const regulatoryLineageURLArray = [
  'regulatory-lineage/regulations',
  'regulatory-lineage/pillars/search',
  'regulatory-lineage/topics/search',
  'regulatory-lineage/sub-topics/search',
  'regulatory-lineage/regulatory-texts/search',
  'regulatory-lineage/obligation-texts/search',
  'regulatory-lineage/primary-metrics/search',
  'regulatory-lineage/secondary-metrics/search',
  'regulatory-lineage/core-data/search',
  'regulatory-lineage/core-data-details/search'
];
export const regulatoryLineageRequestData = [
  'regulationNode',
  'pillarNode',
  'topicNode',
  'subTopicNode',
  'requirementNode',
  'obligationNode',
  'primaryMetricNode',
  'constituentNode',
  'coreAttributeNode'
];
export const REGULATORY_LINEAGE_MAX_LEVEL_X = 8;

export const sapLineageURLArray = [
  'sap-lineage/pillars',
  'sap-lineage/guidelines/search',
  'sap-lineage/sub-topics/search',
  'sap-lineage/obligation-texts/search',
  'sap-lineage/primary-metrics/search',
  'sap-lineage/general-calculations/search',
  'sap-lineage/scenarios/search',
  'sap-lineage/logics/search',
  'sap-lineage/field-mappings/search',
  'sap-lineage/modules/search',
  'sap-lineage/tables/search',
  'sap-lineage/fields/search'
];
export const sapLineageRequestData = [
  'pillarNode',
  'guidelineNode',
  'subTopicNode',
  'obligationNode',
  'primaryMetricNode',
  'generalCalculationNode',
  'scenarioNode',
  'logicNode',
  'fieldMappingNode',
  'moduleNode',
  'tableNode'
];
export const SAP_LINEAGE_MAX_LEVEL_X = 11;

export const THEMATIC_LINEAGE_MAX_LEVEL_X = 7;

export const thematicLineageApi = {
  generateUrl: (columnId?: number) =>
    THEMATIC_LINEAGE_URLS.filter((urlData) => urlData.levelX === columnId)[0].url
};

// SAP
export const dataSourcingApi = {
  dataSourcing: 'sap-data-sourcing/regulations-pillars-topics',
  gridData: 'sap-data-sourcing/regulation-data-grid/search',
  downloadData: 'sap-data-sourcing/export',
  disclosure: 'sap-data-sourcing/disclosures/search',
  domain: 'sap-data-sourcing/domains/search',
  scenario: 'sap-data-sourcing/scenarios/search'
};

export const dataCategoryApi = [
  'category-explorer/pillars',
  'category-explorer/themes/search',
  'category-explorer/topics/search',
  'category-explorer/sub-topics/search',
  'category-explorer/core-data-details/search',
  'category-explorer/export'
];

export const sapHomePageDataUrl = `sap-data-sourcing/blobData/homePage`;

export const DESKTOP_WIDTH = 1366;

export const THEMATIC_LINEAGE_URLS = [
  {
    url: 'thematic-lineage/pillars',
    levelX: 0,
    name: 'sustainabilityPillars'
  },
  {
    url: 'thematic-lineage/themes/search',
    levelX: 1,
    name: 'themes'
  },
  {
    url: 'thematic-lineage/topics/search',
    levelX: 2,
    name: 'topics'
  },
  {
    url: 'thematic-lineage/sub-topics/search',
    levelX: 3,
    name: 'subTopics'
  },
  {
    url: 'thematic-lineage/obligation-texts/search',
    levelX: 4,
    name: 'obligationText'
  },
  {
    url: 'thematic-lineage/primary-metrics/search',
    levelX: 5,
    name: 'primaryMetrics'
  },
  {
    url: 'thematic-lineage/secondary-metrics/search',
    levelX: 6,
    name: 'secondaryMetrics'
  },
  {
    url: 'thematic-lineage/core-data/search',
    levelX: 7,
    name: 'coreData'
  },
  {
    url: 'thematic-lineage/core-data-details/search',
    levelX: 8
  }
];

export const thematicLineageRequestData = [
  'pillarNode',
  'themeNode',
  'topicNode',
  'subTopicNode',
  'obligationNode',
  'primaryMetricNode',
  'constituentNode'
];

export const logoutUrl = `/Authentication/logout`;
